<template>
  <div v-if="!error" class="loading">
    <svg :style="`width:${width}px`" xmlns="http://www.w3.org/2000/svg" viewBox="0 -10 454.46 325.33"><g id="Layer_2" data-name="Layer 2"><path class="cls-logo" d="M258.37,138.06a86.53,86.53,0,0,1,21,11.87,55.14,55.14,0,0,1,13.93,16.31,67,67,0,0,1,6.27,14.4V76.7c0-24.29-6.38-43.15-19.67-56.57S247.83,0,223.54,0H223a106.89,106.89,0,0,0-12.34.69q-21.31,2.37-35.7,13a67.79,67.79,0,0,0-6.2,4.94Q148,37.3,148,76.7v.72l.17,3.26A180,180,0,0,0,151.05,112a71.64,71.64,0,0,0,7.72,22.25,57.37,57.37,0,0,0,13.93,16.53,84.07,84.07,0,0,0,20.57,12.07l5.6,2.3h0l11.81,4.84a168.22,168.22,0,0,1,19.51,9.53A48.84,48.84,0,0,1,242,188.79a25.89,25.89,0,0,1,5.79,10.59c.27,1,.5,2.12.69,3.25V231.8q0,16.11-7.62,22.67A25.85,25.85,0,0,1,223.92,261a30,30,0,0,1-7.45-1,25.68,25.68,0,0,1-7.66-3.78,22.19,22.19,0,0,1-3.06-2.85q-6.65-7.62-6.64-21.61l-.24-37.29v-25.7c-2.41-.81-5.13-1.47-7.71-2.57a96.27,96.27,0,0,1-16.84-8.73,54.2,54.2,0,0,1-14.53-13.75,55.52,55.52,0,0,1-8.09-15.16c-2.72-7.48-3.55-11.36-3.78-17.95l-.25,83.86V228q0,21.61,6.63,37.37a57,57,0,0,0,12.46,19.17c.21.22.41.45.63.67q16.13,16.31,43.46,19.25a106.54,106.54,0,0,0,12.18.67h.51q36.87,0,57-20.13c13.43-13.41,19-32.42,19-57V201.5a125.22,125.22,0,0,0-1.47-13.24c-1.67-8.31-4.18-12.72-7.75-19.07a54.76,54.76,0,0,0-13.93-16.32,86.64,86.64,0,0,0-21-11.86l-7-2.88-8.19-3.37a171.54,171.54,0,0,1-20.58-10.17,56,56,0,0,1-12.21-9.54,24.69,24.69,0,0,1-6-10.59A52.57,52.57,0,0,1,200,91.11V73.31q0-14,6.43-21.61c4.19-5,10-7.51,17.53-7.61a26.38,26.38,0,0,1,16.72,6.2c.45.45.9.91,1.32,1.41q6.43,7.62,6.43,21.61V110.6h0V134Z"/></g></svg>
    <p v-if="wait" class="mb-0 small">Please wait...</p>
  </div>
  <p v-else class="mb-0 small">Failed to load. Please refresh and try again.</p>
</template>

<script>
export default {
  name: "Loading",
  props: {
    infinite: {type: Boolean, default: false},
    width: {type: Number, default: 50},
  },
  data () {
    return {
      wait: false,
      error: false
    }
  },
  created () {
    if (!this.infinite) {
      setTimeout(() => {
        this.wait = true
        setTimeout(() => {
          this.error = true
        }, 15000)
      }, 7000)
    }
  }
};
</script>

<style scoped lang="scss">
.loading {
  text-align: center;
  svg {
    margin: 0 auto;
    display: block;
    width: 50px;
  }
}
.cls-logo{
  stroke-width: 6px;
  fill: none;
  stroke-dasharray: 1576;
  animation: dash 4s linear infinite;
}
@keyframes dash {
  from {
    stroke-dashoffset: 3000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
</style>
